import { Bartender } from '@fokke-/bartender.js'
import axios from 'axios'

export default class Site {

  constructor () {
    const siteEl = document.querySelector('.site')

    this.initBartender()
    this.initForms(siteEl)
    this.initSkipLinks()
    this.initInternalLinks(siteEl)
  }

  /**
   * Initialize Bartender
   */
  initBartender () {
    window.bartender = new Bartender({
      debug: false,
    }, {
      focusTrap: true,
    })

    window.bartender.addBar('mobileBar', {
      el: '.mobileBar',
      position: 'right',
      mode: 'float',
    })

    const mobileBarToggleButtons = document.querySelectorAll('.mobileBarToggle')
    if (mobileBarToggleButtons.length) {
      for (const button of mobileBarToggleButtons) {
        button.addEventListener('click', (e) => {
          window.bartender.toggle('mobileBar', e.target)
        })
      }
    }
  }

  initForms (parentEl) {
    const forms = parentEl.querySelectorAll('form')
    if (!forms.length) return

    for (const item of forms) {
      const parentEl = item.parentElement

      for (const el of Array.from(item.elements)) {
        if (el.name === 'tel') {
          el.setAttribute('type', 'tel')
        }

        if (el.classList.contains('required')) {
          const requiredText = document.createElement('span')
          requiredText.setAttribute('aria-hidden', true)
          requiredText.textContent = ` ${item.getAttribute('data-required-label')}`
          el.parentElement.parentElement.querySelector('.field__label').append(requiredText)
        }
      }

      item.addEventListener('submit', (e) => {
        e.preventDefault()

        // Disable inputs
        for (const el of Array.from(e.target.elements)) {
          el.setAttribute('disabled', true)
        }

        // Build post data
        const data = Array.from(e.target.elements).reduce((acc, item) => {
          acc.append(item.getAttribute('name'), item.value)

          return acc
        }, new FormData())

        axios({
          method: 'post',
          url: e.target.getAttribute('data-form-page'),
          data: data,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        }).then(response => {
          parentEl.innerHTML = response.data
        }).catch(error => {
          parentEl.innerHTML = error.response.data
        }).finally(() => {
          parentEl.focus()
          this.initForms(parentEl)
        })
      })

    }
  }

  initSkipLinks () {
    const skipLinks = document.querySelectorAll('.skip-link:not([data-skip-link])')
    if (skipLinks.length) {
      const skipToBlur = event => {
        if (event.target.getAttribute('data-tabindex')) {
          event.target.removeAttribute('tabindex')
          event.target.removeAttribute('data-tabindex')
        }
      }
      skipLinks.forEach(skipLink => {
        skipLink.setAttribute('data-skip-link', true)
        skipLink.addEventListener('click', event => {
          const skipTo = document.getElementById(event.target.href.split('#')[1])
          if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
            event.preventDefault()
            skipTo.setAttribute('tabindex', '-1')
            skipTo.setAttribute('data-tabindex', true)
            skipTo.addEventListener('blur', skipToBlur)
            skipTo.addEventListener('focusout', skipToBlur)
            skipTo.focus()
          }
        })
      })
    }
  }

  initInternalLinks (parentEl) {
    parentEl = parentEl || document

    const items = parentEl.querySelectorAll('[href^="#"]')
    if (!items.length) return

    for (const item of items) {
      const skipTo = parentEl.querySelector(`#${item.href.split('#').pop()}`)
      if (!skipTo) return
      skipTo.setAttribute('tabindex', -1)

      item.addEventListener('click', () => {
        skipTo.focus()
      })
    }
  }

}
